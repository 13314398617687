import React, {useEffect, useState} from 'react'
import { Router, navigate } from '@reach/router'
import { ThemeProvider } from "@material-ui/core"
import theme from "../lib/theme"
import { Provider } from "react-redux"
import ReduxStore from "../components/app/store"
import hasParams from "../components/has_params"

import Onboard from "../components/app/features/onboard/onboard"

const OnboardPage = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={ReduxStore}>
        <Onboard
          {...props}
          />
      </Provider>
    </ThemeProvider>
  );
}

export default hasParams(OnboardPage);
